import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import useLanguages from '@/hooks/public/useLanguages';
import {
  useDifficultyQueryState,
  useTargetLanguageCodeQueryState,
  useTopicQueryState,
} from '@/hooks/public/useQueryParams';

export default function LanguageSelect() {
  const [targetLanguageCode, setTargetLanguageCode] = useTargetLanguageCodeQueryState();
  const [, setDifficulty] = useDifficultyQueryState();
  const [, setTopic] = useTopicQueryState();
  const { data: languages, isLoading } = useLanguages();

  return (
    <Select
      value={targetLanguageCode ?? ''}
      onValueChange={(value) => {
        setTargetLanguageCode(value);
        setDifficulty(-1);
        setTopic('any');
      }}
      disabled={languages?.length === 0}
    >
      <SelectTrigger className='w-auto'>
        <SelectValue
          placeholder={
            isLoading
              ? 'Loading...'
              : languages?.length === 0
              ? 'No languages available'
              : 'Select language'
          }
        />
      </SelectTrigger>
      <SelectContent>
        {languages?.map((language) => (
          <SelectItem key={language.languageCode} value={language.languageCode}>
            {language.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
