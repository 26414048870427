import { create } from 'zustand';

import { audioUrl } from '@/util';

import type { PlayableAudio } from '@/types';

interface AudioState {
  currentAudioPlayer: HTMLAudioElement | null;
  playingAudioId: string | null;
  playAudio: (audio: PlayableAudio) => void;
  stopAudio: () => void;
}

const usePlayAudio = create<AudioState>((set) => ({
  currentAudioPlayer: null,
  playingAudioId: null,
  playAudio: (audio: PlayableAudio) => {
    set((state) => {
      if (state.currentAudioPlayer) {
        state.currentAudioPlayer.pause();
        state.currentAudioPlayer.currentTime = 0; // Reset to start
      }

      const audioPlayer = new Audio(audioUrl(audio));
      audioPlayer.play();

      audioPlayer.addEventListener('ended', () => {
        set({ playingAudioId: null });
      });

      return { currentAudioPlayer: audioPlayer, playingAudioId: audio.id };
    });
  },
  stopAudio: () => {
    set((state) => {
      if (state.currentAudioPlayer) {
        state.currentAudioPlayer.pause();
        state.currentAudioPlayer.currentTime = 0; // Reset to start
        return { currentAudioPlayer: null, playingAudioId: null };
      }
      return state;
    });
  },
}));

export default usePlayAudio;
