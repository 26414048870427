import { parseAsBoolean, parseAsInteger, parseAsString, useQueryState } from 'nuqs';

import { defaultTargetLanguageCode } from '@/config';

export const useDifficultyQueryState = () =>
  useQueryState('difficulty', parseAsInteger.withDefault(-1));
export const useTopicQueryState = () => useQueryState('topic', parseAsString.withDefault('any'));

export const useShowHighlightsQueryState = () =>
  useQueryState('showHighlights', parseAsBoolean.withDefault(false));

export const useCollapsedQueryState = () =>
  useQueryState('collapsed', parseAsBoolean.withDefault(false));

export const useTargetLanguageCodeQueryState = () =>
  useQueryState('targetLanguageCode', parseAsString.withDefault(defaultTargetLanguageCode));

export const useCardIdQueryState = () => useQueryState('cardId', parseAsString);

export const useQueryParams = () => {
  const [difficulty] = useDifficultyQueryState();
  const [topic] = useTopicQueryState();
  const [showHighlights] = useShowHighlightsQueryState();
  const [collapsed] = useCollapsedQueryState();
  const [targetLanguageCode] = useTargetLanguageCodeQueryState();
  const [cardId] = useCardIdQueryState();

  return {
    difficulty,
    topic,
    showHighlights,
    collapsed,
    targetLanguageCode,
    cardId,
  };
};
