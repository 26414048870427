'use client';

import { CircleX, RefreshCcw } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';

import AlertError from '@/components/AlertError';
import CopyCardLink from '@/components/LanguageCard/CopyCardLink';
import DifficultySelect from '@/components/LanguageCard/DifficultySelect';
import LanguageCard from '@/components/LanguageCard/LanguageCard';
import LanguageSelect from '@/components/LanguageCard/LanguageSelect';
import TopicSelect from '@/components/LanguageCard/TopicSelect';
import LoadingSpinner from '@/components/LoadingSpinner';
import Tooltip from '@/components/ToolTip';

import {
  useCardIdQueryState,
  useDifficultyQueryState,
  useShowHighlightsQueryState,
  useTargetLanguageCodeQueryState,
  useTopicQueryState,
} from '@/hooks/public/useQueryParams';
import useRandomCard from '@/hooks/public/useRandomCard';
import usePlayAudio from '@/hooks/usePlayAudio';

export default function RandomLanguageCard() {
  const [cardId, setCardId] = useCardIdQueryState();
  const [difficulty, setDifficulty] = useDifficultyQueryState();
  const [targetLanguageCode] = useTargetLanguageCodeQueryState();
  const [topic, setTopic] = useTopicQueryState();
  const [showHighlights, setShowHighlights] = useShowHighlightsQueryState();

  const { card, isLoading, refetch, error } = useRandomCard({
    initialCardId: cardId || undefined,
    targetLanguageCode,
    difficulty,
    topic,
  });
  const [isSpinning, setIsSpinning] = useState(false);
  const { stopAudio } = usePlayAudio();

  useHotkeys(
    'enter',
    () => {
      handleRefetch();
    },
    { preventDefault: true }
  );

  useHotkeys(
    'h',
    () => {
      setShowHighlights(!showHighlights);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'c',
    () => {
      handleReset();
    },
    { preventDefault: true }
  );

  const handleRefetch = () => {
    setIsSpinning(true);
    stopAudio();
    refetch();

    setTimeout(() => setIsSpinning(false), 700);
  };

  const handleReset = () => {
    setDifficulty(null);
    setTopic(null);
    handleRefetch();
  };

  useEffect(() => {
    if (card) setCardId(card.id);
  }, [card, setCardId]);

  useEffect(() => {
    refetch();
  }, [difficulty, topic, targetLanguageCode, refetch]);

  return (
    <div className='flex flex-col items-center justify-center gap-4'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='w-full flex flex-row gap-2 justify-end'>
            <TopicSelect />
            <DifficultySelect />
            <LanguageSelect />
            <Button
              variant='outline'
              size='icon'
              // BUG: why doesn't this refetch?
              onClick={handleReset}
              disabled={difficulty === -1 && topic === 'any'}
            >
              <CircleX className='w-4 h-4' />
            </Button>
          </div>

          <div className='w-full flex flex-row gap-4 items-center'>
            <div className='flex-grow'>
              <Tooltip text='enter to refresh card' className='grow'>
                <Button variant='outline' onClick={handleRefetch}>
                  <RefreshCcw className={`w-4 h-4 ${isSpinning ? 'animate-spin' : ''}`} />
                  New Card
                </Button>
              </Tooltip>
            </div>
            <div className='flex flex-row gap-2 items-center justify-end'>
              <Checkbox
                id='showHighlights'
                checked={showHighlights}
                onCheckedChange={(checked) => setShowHighlights(checked === true)}
              />
              <Label htmlFor='showHighlights' className='text-sm text-muted-foreground'>
                Highlight vocabulary
              </Label>
            </div>
            <CopyCardLink card={card} />
          </div>

          {error ? (
            <AlertError title='Error fetching card' description={error.message} />
          ) : card ? (
            <div className='w-full'>
              <LanguageCard
                card={card}
                showHighlights={showHighlights}
                onTopicClick={(t: string) => {
                  setTopic(t);
                  handleRefetch();
                }}
                onDifficultyClick={(d: number) => {
                  setDifficulty(d);
                  handleRefetch();
                }}
              />
              <div className='flex flex-row justify-end text-muted-foreground text-xs pt-1'>
                <Tooltip text='Enter for new card, c to reset filters, h to toggle highlights, space to play audio, arrow up/down to expand/collapse card'>
                  <div className='flex flex-row items-center gap-1'>keyboard shortcuts</div>
                </Tooltip>
              </div>
            </div>
          ) : (
            <AlertError
              title='No cards found'
              description="This combination of difficulty and topic doesn't have any cards (yet)."
              variant='default'
            />
          )}
        </>
      )}
    </div>
  );
}
