import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { useDifficultyQueryState } from '@/hooks/public/useQueryParams';

import { anyDifficulty, difficultyLevels } from '@/config';

export default function DifficultySelect() {
  const [difficulty, setDifficulty] = useDifficultyQueryState();

  return (
    <Select
      value={difficulty === null ? '' : difficulty.toString()}
      onValueChange={(value) => {
        // Handle empty string case
        if (value === '') {
          setDifficulty(anyDifficulty);
        } else {
          setDifficulty(parseInt(value));
        }
      }}
    >
      <SelectTrigger className='w-auto'>
        <SelectValue placeholder='Select difficulty' />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={anyDifficulty.toString()}>Any Difficulty</SelectItem>
        {difficultyLevels.map((level, index) => {
          const difficultyLevel = `${level.level} ${level.label}`;
          const adjustedIndex = index + 1; // Adjust index to start from 1 instead of 0

          return (
            <SelectItem key={difficultyLevel} value={adjustedIndex.toString()}>
              {difficultyLevel}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
