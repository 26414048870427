import { ReactNode } from 'react';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/util';

type ToolTipProps = {
  text: string | ReactNode;
  children: ReactNode;
  delayDuration?: number;
  className?: string;
};
export default function ToolTip({ text, children, delayDuration = 100, className }: ToolTipProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={delayDuration}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          className={cn(
            'max-w-[300px] text-wrap text-black bg-white border-[1px] border-gray-300 rounded-md p-2',
            className
          )}
        >
          {text}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
