'use client';

import { createSelectSchema } from 'drizzle-zod';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { languagesTable } from '@/db/schema';

async function getLanguages() {
  const languagesSchema = createSelectSchema(languagesTable);

  const res = await fetch('/api/site/getLanguages');
  const data = await res.json();

  return z.array(languagesSchema).parse(data);
}

export default function useLanguages() {
  return useQuery({
    queryKey: ['languages'],
    queryFn: getLanguages,
  });
}
