import { relations, sql } from 'drizzle-orm';
import { integer, primaryKey, sqliteTable, text, unique } from 'drizzle-orm/sqlite-core';
import { createInsertSchema, createSelectSchema, createUpdateSchema } from 'drizzle-zod';

import {
  apiProviderTypes,
  apiResourceTypes,
  audioStatuses,
  audioTypes,
  cardStatuses,
  defaultAudioStatus,
  defaultLanguageStatus,
  defaultVocabularyStatus,
  languageStatuses,
  llmProviders,
  topicStatuses,
  ttsProviders,
  vocabularyStatuses,
} from '@/config';
import { ApiUsageUsage, LlmSettings, TtsSettings } from '@/types';

export const languagesTable = sqliteTable('languages', {
  id: text()
    .primaryKey()
    .default(sql`(lower(hex(randomblob(16))))`),
  createdAt: text()
    .default(sql`CURRENT_TIMESTAMP`)
    .notNull(),
  updatedAt: text()
    .default(sql`CURRENT_TIMESTAMP`)
    .$onUpdate(() => sql`CURRENT_TIMESTAMP`)
    .notNull(),

  status: text({ enum: languageStatuses }).notNull().default(defaultLanguageStatus),

  languageCode: text().unique().notNull(),
  name: text().unique().notNull(),
});
export const languagesSchema = createSelectSchema(languagesTable);
export const languagesInsertSchema = createInsertSchema(languagesTable);
export const languagesUpdateSchema = createUpdateSchema(languagesTable);

export const topicsTable = sqliteTable(
  'topics',
  {
    id: text()
      .primaryKey()
      .default(sql`(lower(hex(randomblob(16))))`),
    createdAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .notNull(),
    updatedAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .$onUpdate(() => sql`CURRENT_TIMESTAMP`)
      .notNull(),

    label: text().notNull(),
    keywords: text({ mode: 'json' }).$type<string[]>().default([]),
    status: text({ enum: topicStatuses }).notNull().default('pending'),

    languageId: text()
      .notNull()
      .references(() => languagesTable.id),
  },
  (t) => [unique().on(t.label, t.languageId)]
);
export const topicsSchema = createSelectSchema(topicsTable);
export const topicsInsertSchema = createInsertSchema(topicsTable);
export const topicsUpdateSchema = createUpdateSchema(topicsTable);

export const topicsRelations = relations(topicsTable, ({ one, many }) => ({
  language: one(languagesTable, {
    fields: [topicsTable.languageId],
    references: [languagesTable.id],
  }),
  cards: many(cardsTable),
}));

export const promptsTable = sqliteTable(
  'prompts',
  {
    id: text()
      .primaryKey()
      .default(sql`(lower(hex(randomblob(16))))`),
    createdAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .notNull(),
    updatedAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .$onUpdate(() => sql`CURRENT_TIMESTAMP`)
      .notNull(),

    active: integer().notNull().default(1),

    system: text(),
    prompt: text().notNull(),

    // relations
    languageId: text()
      .notNull()
      .references(() => languagesTable.id),
  },
  (t) => [unique().on(t.system, t.prompt, t.languageId)]
);
export const promptsSchema = createSelectSchema(promptsTable);
export const promptsInsertSchema = createInsertSchema(promptsTable);
export const promptsUpdateSchema = createUpdateSchema(promptsTable);

export const promptsRelations = relations(promptsTable, ({ one }) => ({
  language: one(languagesTable, {
    fields: [promptsTable.languageId],
    references: [languagesTable.id],
  }),
}));

export const cardsTable = sqliteTable(
  'cards',
  {
    id: text()
      .primaryKey()
      .default(sql`(lower(hex(randomblob(16))))`),
    createdAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .notNull(),
    updatedAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .$onUpdate(() => sql`CURRENT_TIMESTAMP`)
      .notNull(),

    llmProvider: text({
      enum: llmProviders,
    }).notNull(),
    llmSettings: text({ mode: 'json' }).$type<LlmSettings>().notNull(),
    status: text({
      enum: cardStatuses,
    })
      .notNull()
      .default('generated'),
    difficulty: integer().notNull().default(1),

    sourceText: text(),
    targetText: text(),
    targetPronunciation: text(),

    explanation: text(),

    // relations
    promptId: text().notNull(),
    topicId: text().notNull(),
    sourceLanguageId: text()
      .notNull()
      .references(() => languagesTable.id),
    targetLanguageId: text()
      .notNull()
      .references(() => languagesTable.id),
  }
  // TODO: enforce uniqueness of 'target' text per translation language
  // (t) => [unique().on(t.source, t.target, t.sourceLanguageId, t.targetLanguageId)]
);
export const cardsSchema = createSelectSchema(cardsTable);
export const cardsInsertSchema = createInsertSchema(cardsTable);
export const cardsUpdateSchema = createUpdateSchema(cardsTable);

export const cardsRelations = relations(cardsTable, ({ one, many }) => ({
  prompt: one(promptsTable, {
    fields: [cardsTable.promptId],
    references: [promptsTable.id],
  }),
  sourceLanguage: one(languagesTable, {
    fields: [cardsTable.sourceLanguageId],
    references: [languagesTable.id],
  }),
  targetLanguage: one(languagesTable, {
    fields: [cardsTable.targetLanguageId],
    references: [languagesTable.id],
  }),
  topic: one(topicsTable, {
    fields: [cardsTable.topicId],
    references: [topicsTable.id],
  }),
  audio: many(audioTable),
  cardsToVocabulary: many(cardsToVocabularyTable),
}));

export const vocabularyTable = sqliteTable(
  'vocabulary',
  {
    id: text()
      .primaryKey()
      .default(sql`(lower(hex(randomblob(16))))`),
    createdAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .notNull(),
    updatedAt: text()
      .default(sql`CURRENT_TIMESTAMP`)
      .$onUpdate(() => sql`CURRENT_TIMESTAMP`)
      .notNull(),

    status: text({ enum: vocabularyStatuses }).notNull().default(defaultVocabularyStatus),

    source: text().notNull(),
    target: text().notNull(),
    pronunciation: text(),

    // relations
    sourceLanguageId: text()
      .notNull()
      .references(() => languagesTable.id),
    targetLanguageId: text()
      .notNull()
      .references(() => languagesTable.id),
  },
  (t) => [unique().on(t.source, t.target, t.sourceLanguageId, t.targetLanguageId)]
);
export const vocabularySchema = createSelectSchema(vocabularyTable);
export const vocabularyInsertSchema = createInsertSchema(vocabularyTable);
export const vocabularyUpdateSchema = createUpdateSchema(vocabularyTable);
export const vocabularyRelations = relations(vocabularyTable, ({ one, many }) => ({
  sourceLanguage: one(languagesTable, {
    fields: [vocabularyTable.sourceLanguageId],
    references: [languagesTable.id],
  }),
  targetLanguage: one(languagesTable, {
    fields: [vocabularyTable.targetLanguageId],
    references: [languagesTable.id],
  }),
  audio: many(audioTable),
  cards: many(cardsToVocabularyTable),
}));

export const cardsToVocabularyTable = sqliteTable(
  'cards_to_vocabulary',
  {
    cardId: text()
      .notNull()
      .references(() => cardsTable.id),
    vocabularyId: text()
      .notNull()
      .references(() => vocabularyTable.id),
  },
  (t) => [primaryKey({ columns: [t.cardId, t.vocabularyId] })]
);

export const cardsToVocabularyRelations = relations(cardsToVocabularyTable, ({ one }) => ({
  card: one(cardsTable, {
    fields: [cardsToVocabularyTable.cardId],
    references: [cardsTable.id],
  }),
  vocabulary: one(vocabularyTable, {
    fields: [cardsToVocabularyTable.vocabularyId],
    references: [vocabularyTable.id],
  }),
}));

export const audioTable = sqliteTable('audio', {
  id: text()
    .primaryKey()
    .default(sql`(lower(hex(randomblob(16))))`),
  createdAt: text()
    .default(sql`CURRENT_TIMESTAMP`)
    .notNull(),
  updatedAt: text()
    .default(sql`CURRENT_TIMESTAMP`)
    .$onUpdate(() => sql`CURRENT_TIMESTAMP`)
    .notNull(),

  status: text({ enum: audioStatuses }).notNull().default(defaultAudioStatus),

  ttsProvider: text({
    enum: ttsProviders,
  }).notNull(),
  ttsSettings: text({ mode: 'json' }).$type<TtsSettings>().notNull(),
  urlPath: text().notNull(),
  fileSize: integer().notNull(),
  characterCount: integer().notNull(),

  // polymorphic reference to card or vocabulary
  resourceId: text().notNull(),
  resourceType: text({ enum: audioTypes }).default('card').notNull(),

  languageId: text()
    .notNull()
    .references(() => languagesTable.id),
});
export const audioSchema = createSelectSchema(audioTable);
export const audioInsertSchema = createInsertSchema(audioTable);
export const audioUpdateSchema = createInsertSchema(audioTable).partial();

export const audioRelations = relations(audioTable, ({ one }) => ({
  language: one(languagesTable, {
    fields: [audioTable.languageId],
    references: [languagesTable.id],
  }),
  card: one(cardsTable, {
    fields: [audioTable.resourceId],
    references: [cardsTable.id],
  }),
  vocabulary: one(vocabularyTable, {
    fields: [audioTable.resourceId],
    references: [vocabularyTable.id],
  }),
}));

export const apiUsageTable = sqliteTable('api_usage', {
  id: text()
    .primaryKey()
    .default(sql`(lower(hex(randomblob(16))))`),
  createdAt: text()
    .default(sql`CURRENT_TIMESTAMP`)
    .notNull(),

  type: text({
    enum: apiProviderTypes,
  }).notNull(),
  provider: text({
    enum: [...ttsProviders, ...llmProviders],
  }).notNull(),
  resource: text({
    enum: apiResourceTypes,
  }).notNull(),
  promptId: text().references(() => promptsTable.id),
  resourceCount: integer().notNull(),
  resourceIds: text({ mode: 'json' }).$type<string[]>().notNull(),
  usage: text({ mode: 'json' }).$type<ApiUsageUsage>().notNull(),
  executionTime: integer().default(0),
});
export const apiUsageSchema = createSelectSchema(apiUsageTable);
export const apiUsageInsertSchema = createInsertSchema(apiUsageTable);
export const apiUsageUpdateSchema = createUpdateSchema(apiUsageTable);
