import { AlertCircle } from 'lucide-react';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

type AlertErrorProps = {
  title: string;
  description: string;
  variant?: 'destructive' | 'default';
};
export default function AlertError({
  title,
  description,
  variant = 'destructive',
}: AlertErrorProps) {
  return (
    <Alert variant={variant} className='bg-white'>
      <AlertCircle className='h-4 w-4' />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
}
