import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { anyDifficulty, anyTopic } from '@/config';

import { CardPublic, cardPublicSchema } from '@/types';

async function fetchRandomCard({
  targetLanguageCode,
  difficulty,
  topic,
}: {
  targetLanguageCode: string;
  difficulty?: number;
  topic?: string;
}) {
  const params = new URLSearchParams();

  params.set('targetLanguageCode', targetLanguageCode);
  if (typeof difficulty === 'number' && difficulty !== anyDifficulty)
    params.set('difficulty', difficulty.toString());
  if (topic && topic !== anyTopic) params.set('topic', topic);

  const res = await fetch(`/api/site/getRandomCard?${params.toString()}`);
  const data = await res.json();

  if (res.status !== 200) throw new Error(data.error);

  return data ? cardPublicSchema.parse(data) : null;
}

async function fetchCardById(id: string) {
  const res = await fetch(`/api/site/getCard?cardId=${id}`);
  const data = await res.json();

  if (res.status !== 200) throw new Error(data.error);

  return cardPublicSchema.parse(data);
}

type UseRandomCardProps = {
  initialCardId?: string;
  targetLanguageCode: string;
  difficulty?: number;
  topic?: string;
};
export default function useRandomCard({
  initialCardId,
  targetLanguageCode,
  difficulty,
  topic,
}: UseRandomCardProps) {
  const [initialFetch, setInitialFetch] = useState(true);

  const {
    data: card,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['card'],
    queryFn: async (): Promise<CardPublic | null> => {
      if (initialCardId && initialFetch) {
        const card = await fetchCardById(initialCardId);

        setInitialFetch(false);

        return card;
      }

      return await fetchRandomCard({ targetLanguageCode, difficulty, topic });
    },
    retry: false,
  });

  return { card, isLoading, refetch, error };
}
