'use client';

import humanFormat from 'human-format';
import { MicOff, Pause, Play } from 'lucide-react';

import Tooltip from '@/components/ToolTip';

import usePlayAudio from '@/hooks/usePlayAudio';

import type { PlayableAudio } from '@/types';

type PlayAudioProps = {
  audio: PlayableAudio | undefined;
  tooltip?: string;
};
export default function PlayAudio({ audio, tooltip }: PlayAudioProps) {
  const { playingAudioId, playAudio, stopAudio } = usePlayAudio();

  if (!audio)
    return (
      <Tooltip text='No audio available'>
        <MicOff className='h-6 w-6' />
      </Tooltip>
    );

  const isPlaying = playingAudioId === audio.id;

  return (
    <Tooltip text={tooltip || `${audio.ttsProvider} (${humanFormat.bytes(audio.fileSize)})`}>
      {isPlaying ? (
        <Pause onClick={() => stopAudio()} />
      ) : (
        <Play onClick={() => playAudio(audio)} />
      )}
    </Tooltip>
  );
}
