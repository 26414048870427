import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { useTopicQueryState } from '@/hooks/public/useQueryParams';
import useTopics from '@/hooks/public/useTopics';

export default function TopicSelect() {
  const [topic, setTopic] = useTopicQueryState();
  const { data: topics, isLoading } = useTopics();

  return (
    <Select value={topic} onValueChange={(value) => setTopic(value)} disabled={isLoading}>
      <SelectTrigger className='w-auto'>
        <SelectValue
          placeholder={
            isLoading ? 'Loading...' : topics?.length === 0 ? 'No topics available' : 'Select topic'
          }
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value='any'>Any Topic</SelectItem>
        {topics?.map((topic) => {
          return (
            <SelectItem key={topic.label} value={topic.label}>
              {topic.label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
