import { Link } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { useQueryParams } from '@/hooks/public/useQueryParams';

import { anyDifficulty, anyTargetLanguageCode, anyTopic } from '@/config';
import { env } from '@/env';
import { cardUrl } from '@/util';

import { CardPublic } from '@/types';

type CopyCardLinkProps = {
  card: CardPublic | null | undefined;
};
export default function CopyCardLink({ card }: CopyCardLinkProps) {
  const { difficulty, topic, targetLanguageCode } = useQueryParams();

  function copyFilterLink() {
    const params = new URLSearchParams();

    if (difficulty && difficulty !== anyDifficulty) params.set('difficulty', difficulty.toString());
    if (topic && topic !== anyTopic) params.set('topic', topic);
    if (targetLanguageCode && targetLanguageCode !== anyTargetLanguageCode)
      params.set('targetLanguageCode', targetLanguageCode);

    const url = `${env.NEXT_PUBLIC_SITE_URL}/?${params.toString()}`;

    navigator.clipboard.writeText(url);
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Link className='size-4 mr-2' />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        {card && (
          <DropdownMenuItem
            onClick={() => {
              navigator.clipboard.writeText(cardUrl(card));
            }}
          >
            Copy card link
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={() => copyFilterLink()}>Copy filter link</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
