'use client';

import { ChevronDown } from 'lucide-react';
import { Fragment } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';

import AlertError from '@/components/AlertError';
import { VocabHighlightedText } from '@/components/LanguageCard/VocabHighlightedText';
import PlayAudio from '@/components/PlayAudio';
import Tooltip from '@/components/ToolTip';

import { useCollapsedQueryState } from '@/hooks/public/useQueryParams';
import usePlayAudio from '@/hooks/usePlayAudio';

import { cn, difficultyLevelShort, getPreferredAudio } from '@/util';

import { CardPublic } from '@/types';

type CardProps = {
  card: CardPublic;
  showHighlights: boolean;
  onTopicClick?: (topic: string) => void;
  onDifficultyClick?: (difficulty: number) => void;
};
export default function LanguageCard({
  card,
  showHighlights,
  onTopicClick,
  onDifficultyClick,
}: CardProps) {
  const { playAudio, stopAudio, playingAudioId } = usePlayAudio();
  const [collapsed, setCollapsed] = useCollapsedQueryState();
  const toggleCollapsible = () => setCollapsed((prev) => !prev);

  useHotkeys(
    'arrowDown',
    () => {
      setCollapsed(false);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'arrowUp',
    () => {
      setCollapsed(true);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'space',
    () => {
      if (audio) {
        if (playingAudioId === audio.id) {
          stopAudio();
        } else {
          playAudio(audio);
        }
      }
    },
    { preventDefault: true }
  );

  if (!card) return <AlertError title='No card found' description='No card found' />;

  const {
    targetText,
    sourceText,
    targetPronunciation,
    explanation,
    vocabulary,
    topic,
    targetLanguage,
    audio: [audio],
  } = card;

  return (
    <Card className='lg:w-[48rem] bg-amber-50'>
      <CardHeader>
        <CardTitle className='flex flex-row gap-2 w-full items-center justify-end'>
          <Badge
            variant='outline'
            className={cn(
              'font-normal bg-white tracking-wider',
              onDifficultyClick && 'cursor-pointer'
            )}
            onClick={() => onDifficultyClick?.(card.difficulty)}
          >
            {targetLanguage.name} - {difficultyLevelShort(card.difficulty)}
          </Badge>
          <Badge
            variant='outline'
            className={cn('font-normal bg-white tracking-wider', onTopicClick && 'cursor-pointer')}
            onClick={() => onTopicClick?.(topic.label || '')}
          >
            topic: {topic.label}
          </Badge>
          <Badge variant='outline' className='font-medium bg-white tracking-wider'>
            LLM: {card.llmProvider}
          </Badge>
          {audio?.ttsProvider && (
            <Badge variant='outline' className='font-medium bg-white tracking-wider'>
              TTS: {audio.ttsProvider}
            </Badge>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Collapsible className='w-full' onOpenChange={toggleCollapsible} open={!collapsed}>
          <CollapsibleTrigger className='flex items-center gap-4 w-full'>
            {audio && (
              <div onClick={(e) => e.stopPropagation()}>
                <PlayAudio
                  audio={getPreferredAudio(card, 'elevenlabs')}
                  tooltip={'space bar to play'}
                />
              </div>
            )}
            <div className='text-2xl font-normal text-left flex-1'>
              {showHighlights ? (
                <VocabHighlightedText text={targetText} vocabulary={vocabulary} />
              ) : (
                targetText
              )}
            </div>
            <Tooltip text={'up / down arrow keys to expand / collapse'}>
              <ChevronDown
                className={`w-4 h-4 shrink-0 ml-2 transform transition-transform duration-300 self-start ${
                  collapsed ? 'rotate-0' : 'rotate-180'
                }`}
              />
            </Tooltip>
          </CollapsibleTrigger>
          <CollapsibleContent onClick={toggleCollapsible}>
            <div className='text-lg cursor-pointer'>
              <div className='pt-2'>{sourceText}</div>
              <div className='text-muted-foreground'>{targetPronunciation}</div>
              <div className='text-center p-4 text-balance'>
                {vocabulary.map((word, index) => (
                  <Fragment key={index}>
                    {index > 0 && <span className='mx-2'>&middot;</span>}
                    <span className='text-muted-foreground text-sm'>
                      <span className='text-black'>{word.target}</span>{' '}
                      {word?.pronunciation && `(${word.pronunciation})`} : {word.source}
                    </span>
                  </Fragment>
                ))}
              </div>
              <div>{explanation}</div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </CardContent>
      <CardFooter>
        <div className='w-full flex flex-row justify-end text-xs text-muted-foreground'>
          Card ID: {card.id}, last updated: {card.updatedAt}, status: {card.status}
        </div>
      </CardFooter>
    </Card>
  );
}
