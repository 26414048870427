import { z } from 'zod';

import { TextToSpeechParams } from '@/cli/lib/textToSpeechProviders';
import {
  audioStatuses,
  audioTypes,
  cardStatuses,
  difficultyLevels,
  languageStatuses,
  llmProviders,
  topicStatuses,
  ttsGenderOptions,
  ttsProviders,
  ttsSpeedOptions,
} from '@/config';
import {
  getAllAudio,
  getAllCards,
  getAllPromptsWithCardCount,
  getAllVocabulary,
  getLanguageDistribution,
  getRandomCard,
  getTopicsWithCardCount,
} from '@/db/queries';
import {
  apiUsageInsertSchema,
  apiUsageSchema,
  apiUsageUpdateSchema,
  audioInsertSchema,
  audioSchema,
  audioUpdateSchema,
  cardsInsertSchema,
  cardsSchema,
  cardsUpdateSchema,
  languagesInsertSchema,
  languagesSchema,
  languagesUpdateSchema,
  promptsInsertSchema,
  promptsSchema,
  promptsUpdateSchema,
  topicsInsertSchema,
  topicsSchema,
  topicsUpdateSchema,
  vocabularyInsertSchema,
  vocabularySchema,
  vocabularyUpdateSchema,
} from '@/db/schema';

export type Language = z.infer<typeof languagesSchema>;
export type LanguageAdmin = Awaited<ReturnType<typeof getLanguageDistribution>>[number];
export type LanguageInsert = z.infer<typeof languagesInsertSchema>;
export type LanguageUpdate = z.infer<typeof languagesUpdateSchema>;
export type LanguageStatus = (typeof languageStatuses)[number];

export type Topic = z.infer<typeof topicsSchema>;
export type TopicAdmin = Awaited<ReturnType<typeof getTopicsWithCardCount>>[number];
export type TopicInsert = z.infer<typeof topicsInsertSchema>;
export type TopicUpdate = z.infer<typeof topicsUpdateSchema>;
export type TopicStatus = (typeof topicStatuses)[number];

export type Prompt = z.infer<typeof promptsSchema>;
export type PromptAdmin = Awaited<ReturnType<typeof getAllPromptsWithCardCount>>[number];
export type PromptInsert = z.infer<typeof promptsInsertSchema>;
export type PromptUpdate = z.infer<typeof promptsUpdateSchema>;

export type Card = z.infer<typeof cardsSchema>;
export type CardInsert = z.infer<typeof cardsInsertSchema>;
export type CardUpdate = z.infer<typeof cardsUpdateSchema>;
export type CardAdmin = Awaited<ReturnType<typeof getAllCards>>[number];
export type CardAdminPublic = Awaited<ReturnType<typeof getRandomCard>>;
export type CardStatus = (typeof cardStatuses)[number];

export type Vocabulary = z.infer<typeof vocabularySchema>;
export type VocabularyInsert = z.infer<typeof vocabularyInsertSchema>;
export type VocabularyUpdate = z.infer<typeof vocabularyUpdateSchema>;
export type VocabularyAdmin = Awaited<ReturnType<typeof getAllVocabulary>>[number];

export type Audio = z.infer<typeof audioSchema>;
export type AudioInsert = z.infer<typeof audioInsertSchema>;
export type AudioUpdate = z.infer<typeof audioUpdateSchema>;
export type AudioStatus = (typeof audioStatuses)[number];

export type AudioWithRelationsBase = Omit<
  Awaited<ReturnType<typeof getAllAudio>>[number],
  'card' | 'vocabulary'
>;

export type CardAudioWithRelations = AudioWithRelationsBase & {
  resourceType: 'card';
  card: Card;
};

export type VocabularyAudioWithRelations = AudioWithRelationsBase & {
  resourceType: 'vocabulary';
  vocabulary: Vocabulary;
};

export type AudioWithRelations = CardAudioWithRelations | VocabularyAudioWithRelations;

export type PlayableAudio = Pick<Audio, 'id' | 'urlPath' | 'ttsProvider' | 'fileSize'>;

export type ApiUsage = z.infer<typeof apiUsageSchema>;
export type ApiUsageInsert = z.infer<typeof apiUsageInsertSchema>;
export type ApiUsageUpdate = z.infer<typeof apiUsageUpdateSchema>;

export type LlmUsage = {
  promptTokens: number;
  completionTokens: number;
  totalTokens: number;
};

export type TtsUsage = {
  characterCount: number;
};

export type ApiUsageUsage = LlmUsage | TtsUsage;

export const translationSchema = z.object({
  target: z.string(),
  source: z.string(),
  pronunciation: z.string().nullable(),
});
export type Translation = z.infer<typeof translationSchema>;
export const cardContentSchema = z.object({
  text: translationSchema,
  vocabulary: z.array(translationSchema),
  explanation: z.string(),
});
export type CardContent = z.infer<typeof cardContentSchema>;

export const cardPublicSchema = z.object({
  id: z.string(),
  vocabulary: z.array(translationSchema),
  targetText: z.string(),
  sourceText: z.string(),
  targetPronunciation: z.string(),
  explanation: z.string(),
  topic: z.object({
    label: z.string(),
  }),
  audio: z.array(
    z.object({
      id: z.string(),
      ttsProvider: z.enum(ttsProviders),
      urlPath: z.string(),
      fileSize: z.number(),
    })
  ),
  targetLanguage: z.object({
    languageCode: z.string(),
    name: z.string(),
  }),
  status: z.string(),
  difficulty: z.number(),
  llmProvider: z.enum(llmProviders),
  updatedAt: z.string(),
});
export type CardPublic = z.infer<typeof cardPublicSchema>;

export type CardSettings = {
  cardCount: number;
  difficulty: number;
  sourceLanguage: Language;
  targetLanguage: Language;
  topic: Topic;
};
export type DifficultyLevel = (typeof difficultyLevels)[number];

export type PromptAndSystem = {
  system: string;
  prompt: string;
};

export type LlmProvider = (typeof llmProviders)[number];

export type LlmSettings = {
  model: string;
  temperature: number;
  maxTokens: number;
  tokensPerCard?: number;
};
export type LlmDefaults = Record<LlmProvider, LlmSettings>;

export type TtsProvider = (typeof ttsProviders)[number];
export type TtsSpeed = (typeof ttsSpeedOptions)[number];
export type TtsGender = (typeof ttsGenderOptions)[number];
export type TtsProviderGenerator = (options: TextToSpeechParams) => Promise<number | undefined>;

export const ttsSettingsSchema = z.object({
  voiceId: z.string(),
  gender: z.enum(ttsGenderOptions).optional(),
  speed: z.enum(ttsSpeedOptions).optional(),
  model: z.string().optional(),
  language: z.string().optional(),
  engine: z.string().optional(),
});
export type TtsSettings = z.infer<typeof ttsSettingsSchema>;
export type TtsDefaults = Record<TtsProvider, TtsSettings>;

export type AudioResourceType = (typeof audioTypes)[number];
