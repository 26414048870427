'use client';

import { useQuery } from '@tanstack/react-query';
import { createSelectSchema } from 'drizzle-zod';
import { z } from 'zod';

import { topicsTable } from '@/db/schema';

async function getTopics() {
  const topicsSchema = createSelectSchema(topicsTable);

  const res = await fetch('/api/site/getTopics');
  const data = await res.json();

  return z.array(topicsSchema).parse(data);
}

export default function useTopics() {
  return useQuery({
    queryKey: ['topics'],
    queryFn: getTopics,
  });
}
