import { Translation } from '@/types';

import Tooltip from '@/components/ToolTip';

type VocabHighlightedTextProps = {
  text: string;
  vocabulary: Translation[];
};
export const VocabHighlightedText = ({ text, vocabulary }: VocabHighlightedTextProps) => {
  const result: React.ReactNode[] = [];

  const sortedVocabulary = [...vocabulary].sort((a, b) => b.target.length - a.target.length);

  let currentIndex = 0;
  let lastProcessedIndex = 0;

  while (currentIndex < text.length) {
    let matched = false;

    for (const word of sortedVocabulary) {
      if (text.startsWith(word.target, currentIndex)) {
        if (currentIndex > lastProcessedIndex) {
          result.push(text.slice(lastProcessedIndex, currentIndex));
        }

        const tooltipText = (
          <div className='max-w-[400px]'>
            <div className='text-xl'>
              {word.target} ({word.pronunciation})
            </div>
            <div className='flex flex-row justify-between gap-2'>
              <div className='text-lg text-muted-foreground'>{word.source}</div>
            </div>
          </div>
        );

        result.push(
          <Tooltip key={currentIndex} text={tooltipText}>
            <span className='decoration-dotted underline-offset-8 cursor-help bg-yellow-200 rounded-md'>
              {word.target}
            </span>
          </Tooltip>
        );
        currentIndex += word.target.length;
        lastProcessedIndex = currentIndex;
        matched = true;
        break;
      }
    }

    if (!matched) {
      result.push(text[currentIndex]);
      currentIndex++;
      lastProcessedIndex = currentIndex;
    }
  }

  return result;
};
